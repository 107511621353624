import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Waves.module.scss';

const Waves = ({ children, category }) => (
  <div className={classNames(styles.waves, `${styles[`waves_${category}`]}`)}>
    {children}
  </div>
);

Waves.propTypes = {
  children: PropTypes.node.isRequired,
  category: PropTypes.string.isRequired,
};

export default Waves;
