import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import styles from './Header.module.scss';

const Columnist = ({ featureName, creatorImage, creatorName }) => {
  const trademarkedFeatureNames = ['Miss Manners'];

  return (
    <div className={styles.header}>
      <div className={styles.header__content}>
        <div className="media">
          <img
            src={creatorImage}
            className={styles.header__image}
            alt={`${featureName} by ${creatorName}`}
          />
          <div className={classNames('media-body', styles.header__meta)}>
            {trademarkedFeatureNames.includes(featureName) ? (
              <h1 className={styles.header__feature}>
                {featureName}
                <sup className={styles.header__trademark}>&reg;</sup>
              </h1>
            ) : (
              <h1 className={styles.header__feature}>{featureName}</h1>
            )}
            <span
              className={styles.header__creator}
            >{`by ${creatorName}`}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

Columnist.propTypes = {
  featureName: PropTypes.string.isRequired,
  creatorImage: PropTypes.string.isRequired,
  creatorName: PropTypes.string.isRequired,
};

export default Columnist;
