import React, { useState, createContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import creatorByline from 'src/lib/utilities/feature/creatorByline';

import Waves from 'src/components/columnist/Waves';
import Container from 'src/components/global/layouts/Container';
import Header from 'src/components/columnist/Header';
import Secondary from 'src/components/global/navigation/Secondary';
import Divider from 'src/components/global/dividers/Divider';

const FeatureContext = createContext({});

const FeatureTemplate = ({ children }) => {
  const [featureData, updateFeatureData] = useState(null);

  const setFeatureData = (data) => {
    const pageLinks = ['archives'];

    if (data.hasAbout) {
      pageLinks.splice(0, 0, 'about');
    }

    if (data.featureShortName === 'dearabby') {
      pageLinks.push('contact', 'polls');
    }

    updateFeatureData({ ...data, pageLinks });
  };

  // memoize to prevent unnecessary rerenders:
  // https://github.com/yannickcr/eslint-plugin-react/pull/2763
  const contextValue = useMemo(() => {
    return { set: setFeatureData };
  }, []);

  return (
    <>
      <FeatureContext.Provider value={contextValue}>
        {featureData && (
          <Waves category={featureData.category}>
            <Container>
              <Header
                featureName={featureData.featureName}
                creatorImage={featureData.featureAvatarUrls.mid}
                creatorName={
                  featureData.bylineOverride
                    ? featureData.bylineOverride
                    : creatorByline(featureData.creators)
                }
              />
              <Secondary
                shortName={featureData.featureShortName}
                category={featureData.category}
                pageLinks={featureData.pageLinks}
              />
              <Divider />
            </Container>
          </Waves>
        )}
        {children}
      </FeatureContext.Provider>
    </>
  );
};

FeatureTemplate.propTypes = {
  children: PropTypes.node.isRequired,
};

export { FeatureContext, FeatureTemplate };
